<template>
  <div class="user-password-and-privacy-container">
    <v-form v-model="valid" ref="form">
      <v-row no-gutters class="mb-4 px-0 px-sm-3" v-if="!isProfileForm"
        ><label v-html="$t('profile.contacts.email')"/><label
          class="font-weight-bold ml-4"
          v-html="userData.email"
      /></v-row>

      <v-row no-gutters align="center">
        <!-- vecchia password (visibile solo nel form di aggiornamento password nel profilo utente) -->
        <v-col cols="12" sm="6" class="mb-4 ma-sm-0" v-if="showOldPassword">
          <label v-html="`${$t('profile.oldPassword')} *`" class="mr-3" />
          <v-text-field
            :disabled="response.status === 2"
            class="mt-2"
            v-model="userData.oldPassword"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="oldPasswordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            @change="validate"
          />
        </v-col>
        <v-col cols="6" v-if="showOldPassword" />
        <v-col cols="12" sm="6" class="mb-4 ma-sm-0" v-if="showPasswordFields">
          <label
            v-html="
              `${
                showOldPassword
                  ? $t('profile.newPassword')
                  : $t('profile.password')
              } *`
            "
            class="mr-3"
          /><v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary">
                $info
              </v-icon>
            </template>
            {{ $t("profile.passwordInfoTooltip") }}
          </v-tooltip>
          <v-text-field
            :disabled="response.status === 2"
            class="mt-2"
            v-model="userData.password"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            @change="validate"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="showPasswordFields">
          <!-- conferma password (visibile solo in registrazione) -->
          <label v-html="`${$t('profile.passwordConfirm')} *`" />
          <v-text-field
            :disabled="response.status === 2"
            class="mt-sm-2"
            v-model="passwordConfirm"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            @change="validate"
          />
        </v-col>

        <!-- consenso marketing -->
        <v-col cols="12" class="font-weight-bold" v-if="showPrivacyFields">
          <label v-html="$t('profile.privacyMarketing.title')" />
          <i18n path="profile.privacyMarketing.text" tag="div">
            <template v-slot:link>
              <router-link
                target="_blank"
                :to="'/page/regolamento-tigros-card-2/ '"
              >
                <span class="text-body-2 font-weight-bold">
                  {{ $t("profile.privacyMarketing.link") }}</span
                >
              </router-link>
            </template>
          </i18n>
          <v-row no-gutters>
            <v-radio-group
              dense
              v-model="supportData.privacy2"
              mandatory
              :disabled="response.status === 2"
            >
              <v-radio :value="true"
                ><template v-slot:label>
                  <span class="primary--text">{{ $t("common.accept") }}</span>
                </template></v-radio
              >
              <v-radio :value="false"
                ><template v-slot:label>
                  <span class="primary--text">{{ $t("common.refuse") }}</span>
                </template></v-radio
              ></v-radio-group
            >
          </v-row>
        </v-col>

        <!-- consenso profilazione -->
        <v-col cols="12" class="font-weight-bold" v-if="showPrivacyFields">
          <label v-html="$t('profile.privacyProfiling.title')" />
          <i18n path="profile.privacyProfiling.text" tag="div">
            <template v-slot:link>
              <router-link
                target="_blank"
                :to="'/page/regolamento-tigros-card-2/'"
              >
                <span class="text-body-2 font-weight-bold">
                  {{ $t("profile.privacyProfiling.link") }}</span
                >
              </router-link>
            </template>
          </i18n>
          <v-row no-gutters>
            <v-radio-group
              dense
              v-model="supportData.privacy1"
              mandatory
              :disabled="response.status === 2"
            >
              <v-radio :value="true"
                ><template v-slot:label>
                  <span class="primary--text">{{ $t("common.accept") }}</span>
                </template></v-radio
              >
              <v-radio :value="false"
                ><template v-slot:label>
                  <span class="primary--text">{{ $t("common.refuse") }}</span>
                </template></v-radio
              ></v-radio-group
            >
          </v-row>
        </v-col>
        <v-col
          cols="12"
          @click="clicked"
          v-if="isProfileForm"
          class="d-flex align-center my-4"
        >
          <span>{{ $t("profile.privacyProfiling.consult") }}</span>
          <router-link
            to="/page/privacy"
            :target="isCordova ? '_self' : '_blank'"
            class="mx-1"
          >
            <span> {{ $t("profile.privacyProfiling.privacyDisclaimer") }}</span>
          </router-link>
          <span>{{ $t("profile.privacyProfiling.consultComplete") }}</span>
        </v-col>
      </v-row>

      <!-- pulsanti di annullamento e conferma non visibili nel profilo utente -->
      <v-layout
        v-if="showButtons"
        :column="$vuetify.breakpoint.xs"
        :row="$vuetify.breakpoint.smAndUp"
        class="buttons align-sm-center"
      >
        <v-row
          no-gutters
          justify="space-between"
          justify-sm="start"
          class="mt-5 mt-sm-0"
        >
          <v-col cols="5">
            <v-btn
              x-large
              depressed
              block
              class="primary--text"
              v-html="$t('common.back')"
              @click="back"
            />
          </v-col>
          <v-col cols="5">
            <v-btn
              color="primary"
              x-large
              depressed
              block
              v-html="isProfileForm ? $t('common.confirm') : $t('common.next')"
              @click="confirm"
              :disabled="!valid"
            />
          </v-col>
        </v-row>

        <label v-html="$t('profile.mandatoryField')" />
      </v-layout>
    </v-form>
  </div>
</template>

<style lang="scss">
.user-password-and-privacy-container {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    [class*=" col-"] {
      padding: 0 12px !important;
    }
  }
  .v-input--radio-group__input {
    .v-label {
      color: $primary;
    }
  }
}
</style>

<script>
import ConfirmPrivacyOptions from "@/components/profile/form/ConfirmPrivacyOptionsModal";
import { requiredValue } from "~/validator/validationRules";
import clickHandler from "~/mixins/clickHandler";
import { passwordRule } from "@/tigrosValidationRules";
export default {
  name: "UserPasswordAndPrivacy",
  mixins: [clickHandler],
  props: {
    userData: { type: Object, required: false },
    supportData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    response: { type: Object },
    showButtons: { type: Boolean, default: true },
    showPasswordFields: { type: Boolean, default: true },
    showPrivacyFields: { type: Boolean, default: true },
    showOldPassword: { type: Boolean, default: false }
  },
  data() {
    return {
      valid: false,
      passwordConfirm: null,
      showPassword: false,
      oldPasswordRules: [requiredValue("Digitare la password")],
      passwordRules: [requiredValue("Digitare la password"), passwordRule()],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ]
    };
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    back() {
      this.$emit("back");
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate", _this.valid);
          }
        }
      }, 200);
    },
    async confirm() {
      if (this.showPrivacyFields) {
        // caso 1: form di solo aggiornamento privacy
        if (
          this.supportData.privacy1 !== true ||
          this.supportData.privacy2 !== true
        ) {
          let _this = this;
          let res = await _this.$dialog.show(ConfirmPrivacyOptions, {
            waitForResult: true,
            width: 500
          });
          if (res) {
            _this.submit();
          }
        } else {
          this.submit();
        }
      } else {
        // caso 2: form di solo aggiornamento password
        this.submit();
      }
    },
    submit() {
      this.$emit("submit", true, true);
    }
  }
};
</script>
